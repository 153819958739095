(function () {
  const oldErrorHandler = window.onerror;
  const oldConsoleError = console.error;
  window.onerror = function (errorMsg, file, line, col, errorObj) {
    let key; const e = encodeURIComponent;
    const customContext = window.nelmio_js_logger_custom_context;
    let customContextStr = '';
    if (oldErrorHandler) {
      oldErrorHandler(errorMsg, file, line, col, errorObj);
    }
    if (typeof customContext === 'object') {
      for (key in customContext) {
        customContextStr += `&context[${e(key)}]=${e(customContext[key])}`;
      }
    }(new Image()).src = `/nelmio-js-logger/log?msg=${e(errorMsg)}&level=error` + `&context[file]=${e(file)}&context[line]=${e(line)}&context[col]=${e(col)}&context[stack]=${e(errorObj && errorObj.stack)}&context[browser]=${e(navigator.userAgent)}&context[page]=${e(document.location.href)}${customContextStr}`;
  };
  console.error = function (...args) {
    oldConsoleError.apply(console, args);
    const { stack } = new Error();
    const errorMsg = args.join(' ');
    const e = encodeURIComponent;
    (new Image()).src = `/nelmio-js-logger/log?msg=${e(errorMsg)}&level=error` + `&context[browser]=${e(navigator.userAgent)}&context[page]=${e(document.location.href)}&context[stack]=${e(stack)}`;
  };
}());
